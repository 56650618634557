import React from "react";
import styled from "styled-components";

import { Stack, Text } from "@envato/eds";

import MainContent, {
  Column,
  Card,
  ContentSize,
} from "../../components/MainContent";
import Topbar, { TopbarProps } from "../../components/Topbar";
import Footer from "../../components/Footer";

interface LayoutProps extends TopbarProps {
  title: string;
  testId: string;
  size: ContentSize;
  titleButton?: React.ReactNode;
  children?: React.ReactNode;
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Layout: React.FC<LayoutProps> = ({
  title,
  testId,
  size,
  showSignIn,
  showSignUp,
  titleButton,
  children,
}) => {
  return (
    <Stack>
      <Topbar showSignIn={showSignIn} showSignUp={showSignUp} />
      <MainContent size={size}>
        <Column>
          <Card>
            <Stack spacing="4x">
              <Header>
                <Text variant="title-3" text={title} testId={testId} />
                {titleButton}
              </Header>
              {children}
            </Stack>
          </Card>
        </Column>
      </MainContent>
      <Footer />
    </Stack>
  );
};

export default Layout;
